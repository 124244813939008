@keyframes rmwcToolTipIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}
@keyframes rmwcToolTipOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.rmwc-tooltip {
  position: absolute;
  z-index: 9999;
  display: block;
  visibility: visible;
  line-height: 1.5;
  font-size: 0.75rem;
  font-family: inherit;
  padding: 1px;
  opacity: 0.9;
}

.rmwc-tooltip-hidden {
  display: none;
}

.rmwc-tooltip.rmwc-tooltip-zoom-enter,
.rmwc-tooltip.rmwc-tooltip-zoom-leave {
  display: block;
}

.rmwc-tooltip-zoom-enter,
.rmwc-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: 0.15s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rmwc-tooltip-zoom-leave {
  animation-duration: 0.075s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rmwc-tooltip-zoom-enter.rmwc-tooltip-zoom-enter-active,
.rmwc-tooltip-zoom-appear.rmwc-tooltip-zoom-appear-active {
  animation-name: rmwcToolTipIn;
  animation-play-state: running;
}

.rmwc-tooltip-zoom-leave.rmwc-tooltip-zoom-leave-active {
  animation-name: rmwcToolTipOut;
  animation-play-state: running;
}

.rmwc-tooltip-inner {
  display: flex;
  align-items: center;
  padding: 0.33rem 1rem;
  color: var(--mdc-theme-surface, white);
  text-align: left;
  text-decoration: none;
  background-color: var(--mdc-theme-on-surface, black);
  border-radius: 0.25rem;
  min-height: 2rem;
  box-sizing: border-box;
}

.rmwc-tooltip--show-arrow .rmwc-tooltip-arrow {
  display: block;
}

.rmwc-tooltip-arrow {
  display: none;
}

.rmwc-tooltip-arrow,
.rmwc-tooltip-arrow-inner {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rmwc-tooltip-placement-top .rmwc-tooltip-arrow,
.rmwc-tooltip-placement-topLeft .rmwc-tooltip-arrow,
.rmwc-tooltip-placement-topRight .rmwc-tooltip-arrow {
  bottom: -5px;
  margin-left: -6px;
  border-width: 6px 6px 0;
  border-top-color: var(--mdc-theme-on-surface, black);
}

.rmwc-tooltip-placement-top .rmwc-tooltip-arrow-inner,
.rmwc-tooltip-placement-topLeft .rmwc-tooltip-arrow-inner,
.rmwc-tooltip-placement-topRight .rmwc-tooltip-arrow-inner {
  bottom: 1px;
  margin-left: -6px;
  border-width: 6px 6px 0;
  border-top-color: var(--mdc-theme-surface, white);
}

.rmwc-tooltip-placement-top .rmwc-tooltip-arrow {
  left: 50%;
}

.rmwc-tooltip-placement-topLeft .rmwc-tooltip-arrow {
  left: 15%;
}

.rmwc-tooltip-placement-topRight .rmwc-tooltip-arrow {
  right: 15%;
}

.rmwc-tooltip-placement-right .rmwc-tooltip-arrow,
.rmwc-tooltip-placement-rightTop .rmwc-tooltip-arrow,
.rmwc-tooltip-placement-rightBottom .rmwc-tooltip-arrow {
  left: -5px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
  border-right-color: var(--mdc-theme-on-surface, black);
}

.rmwc-tooltip-placement-right .rmwc-tooltip-arrow-inner,
.rmwc-tooltip-placement-rightTop .rmwc-tooltip-arrow-inner,
.rmwc-tooltip-placement-rightBottom .rmwc-tooltip-arrow-inner {
  left: 1px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
  border-right-color: var(--mdc-theme-surface, white);
}

.rmwc-tooltip-placement-right .rmwc-tooltip-arrow {
  top: 50%;
}

.rmwc-tooltip-placement-rightTop .rmwc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}

.rmwc-tooltip-placement-rightBottom .rmwc-tooltip-arrow {
  bottom: 15%;
}

.rmwc-tooltip-placement-left .rmwc-tooltip-arrow,
.rmwc-tooltip-placement-leftTop .rmwc-tooltip-arrow,
.rmwc-tooltip-placement-leftBottom .rmwc-tooltip-arrow {
  right: -5px;
  margin-top: -6px;
  border-width: 6px 0 6px 6px;
  border-left-color: var(--mdc-theme-on-surface, black);
}

.rmwc-tooltip-placement-left .rmwc-tooltip-arrow-inner,
.rmwc-tooltip-placement-leftTop .rmwc-tooltip-arrow-inner,
.rmwc-tooltip-placement-leftBottom .rmwc-tooltip-arrow-inner {
  right: 1px;
  margin-top: -6px;
  border-width: 6px 0 6px 6px;
  border-left-color: var(--mdc-theme-surface, white);
}

.rmwc-tooltip-placement-left .rmwc-tooltip-arrow {
  top: 50%;
}

.rmwc-tooltip-placement-leftTop .rmwc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}

.rmwc-tooltip-placement-leftBottom .rmwc-tooltip-arrow {
  bottom: 15%;
}

.rmwc-tooltip-placement-bottom .rmwc-tooltip-arrow,
.rmwc-tooltip-placement-bottomLeft .rmwc-tooltip-arrow,
.rmwc-tooltip-placement-bottomRight .rmwc-tooltip-arrow {
  top: -5px;
  margin-left: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: var(--mdc-theme-on-surface, black);
}

.rmwc-tooltip-placement-bottom .rmwc-tooltip-arrow-inner,
.rmwc-tooltip-placement-bottomLeft .rmwc-tooltip-arrow-inner,
.rmwc-tooltip-placement-bottomRight .rmwc-tooltip-arrow-inner {
  top: 1px;
  margin-left: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: var(--mdc-theme-surface, white);
}

.rmwc-tooltip-placement-bottom .rmwc-tooltip-arrow {
  left: 50%;
}

.rmwc-tooltip-placement-bottomLeft .rmwc-tooltip-arrow {
  left: 15%;
}

.rmwc-tooltip-placement-bottomRight .rmwc-tooltip-arrow {
  right: 15%;
}

@media (min-width: 768px) {
  .rmwc-tooltip-inner {
    padding: 0.33rem 0.5rem;
    min-height: 1.5rem;
  }
}
